<template>
	<div class="GoodsInfoSSS">
		
		<div class="Top">
			<div class="Left">
				工单详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
	
		
		<div class="List">
			
			<div class="One">
				<li class="Sel" v-if="WorkOrder.Id != 'add'">
					<span class="Sel">工单ID</span>
					<em class="Sel">
						{{WorkOrder.Id}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*分类</span>
					<em class="Sel">
						<el-select placeholder="设置分类" v-model="WorkOrder.CategoryId">
							<template v-for="cat in CategoryList">
								<el-option :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
							</template>
					    </el-select>
					</em>
				</li>
				
				
				<li class="Sel">
					<span class="Sel">*详情</span>
					<em  class="Sel" style="display: block;max-height:400px;max-width: 800px;">
						<el-input
						  type="textarea"
						  :rows="2"
						  placeholder="请输入内容"
						  v-model="WorkOrder.Detail" v-if="WorkOrder.Id == 'add'">
						</el-input>
						
						<p>
							{{WorkOrder.Detail}}
						</p>
						
					</em>
				</li>
				
				
				<li class="Sel" v-if="WorkOrder.Id != 'add'">
					<span class="Sel">状态</span>
					<em class="Sel">
						<el-select placeholder="设置状态" v-model="WorkOrder.Status">
							<el-option :value="50" label="待指派"></el-option>
							<el-option :value="60" label="已指派待处理"></el-option>
							<el-option :value="70" label="处理中"></el-option>
							<el-option :value="80" label="已完结"></el-option>
							<el-option :value="10" label="已删除"></el-option>
					    </el-select>
					</em>
				</li>
				
			
				<li class="Sel">
					<span class="Sel">指派给</span>
					<em class="Sel">
						<el-select placeholder="指派给运营人员" v-model="WorkOrder.AdminId">
							<template v-for="admin in AdminUsers">
								<el-option :label="admin.Account" :value="admin.Id" :key="admin.Id"></el-option>
							</template>
					    </el-select>
					</em>
				</li>
			
				<li v-if="WorkOrder.Id != 'add'" class="Sel">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{WorkOrder.CreatedAt}}
					</em>
				</li>
				
				<li v-if="WorkOrder.Id != 'add'" class="Sel">
					<span class="Sel">最后修改时间</span>
					<em class="Sel">
						{{WorkOrder.UpdatedAt}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel"></span>
					<em class="Sel">
						<el-button @click="UpdateWorkOrder()" type="warning">{{WorkOrder.Id == 'add' ? '添加':'修改'}}</el-button>
					</em>
				</li>
			</div>
			
			<div class="ContractList" v-if="WorkOrder.Id != 'add'">
				<h4><i class="el-icon-chat-line-round"></i>工单交流记录</h4>
				<ul v-for="(contact,contactI) in TalkingList" :key="contactI">
					<li>
						{{contact.CreatedAt}} <b>{{contact.RoleName}}</b>:
					</li>
					<p>{{contact.Detail}}</p>
					<div class="Images" v-if="contact.Images != undefined && contact.Images != null && contact.Images.length > 0">
						<a target="_blank" :href="img" class="Image" v-for="(img,imgI) in contact.Images" :key="imgI">
							<img :src="img" />
						</a>
						  
					</div>
				</ul>
				
				<div class="NewContract">
					<li style="padding: 5px 0px;border-top: 5px solid rgba(0,0,0,0.1);color: rgba(0,0,0,0.4);">新的对话</li>
					<div>
						<el-input
						  type="textarea"
						  :rows="2"
						  v-model="NewContract.Content"
						  placeholder="请输入对话内容"
						  >
						</el-input>
					</div>
					<div class="Images">
						
						<div class="Image" v-for="(img,imgI) in NewContract.ImgList" :key="imgI">

							<img :src="img" />
							<em @click="DelContractImg(imgI)">删除</em>
						</div>
						
						<div class="Image" v-if="NewContract.ImgList.length < 3">
							<el-upload
							  class="avatar-uploader"
							  action="https://jsonplaceholder.typicode.com/posts/"
							  :show-file-list="false" :on-change="getFile" :limit="3" :auto-upload="false">
							<i class="el-icon-plus">图片</i>
							</el-upload>
						</div>
						
					</div>
					<div style="padding-top: 10px;">
						<el-button type="danger" size="mini" @click="AddContract()">提交交流对话</el-button>
					</div>
				</div>
				
			</div>
			
		</div>
		
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Select,Option,Dialog,Upload,InputNumber} from 'element-ui'
	export default {
	  name: 'WorkOrderInfo',
	  props: {
	  },
	  data() {
	      return {
			  WorkOrder:{
				  Id:'',
				  CategoryId:'',
				  CategoryName:'',
				  Detail:'',
				  CreatedAt:'',
				  UpdatedAt:'',
				  Status:50,
				  AdminId:'',
			  },
			  Content:'',
			  ShowContent:false,
			  CategoryList:[],
			  AdminUsers:[],//取出所有管理员账户
			  editorOption:{},
			  uploadModel:'single',//multiple 多个
			  NewContract:{
				  ImgList:[],//图片列表
				  Content:'',//讨论内容
			  },
			  ContactList:[],
			  Hi:'',
			  TalkingList:[],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
		'el-input-number':InputNumber,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少ID，页面无法工作')
			return
		}
		this.WorkOrder.Id = this.$route.params.Id
		if(this.WorkOrder.Id != 'add'){
			this.GetWorkOrder(this.$route.params.Id)
		}
		this.GetAdminList()
		this.GetCategoryList()
		this.GetContractList()
	  },
	  methods:{
		  DelContractImg(_index){
			  this.NewContract.ImgList.splice(_index,1)
		  },
		  UpWorkOrderContent(_val){
		  	this.Content = _val
		  },
		  getFilesChange(file, fileList) {
			  this.uploadModel = 'multiple'
				this.getBase64(file.raw).then(res => {
					this.uploadPic(res)
				});
		  },
		    GetWorkOrder(_id){
		  		let data = {Service:'Help',Class: 'WorkOrder',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.WorkOrder = res.Data
		  		})
		    },  
			UpdateWorkOrder(){
				let data = {
					Service:'Help',
					Class: 'WorkOrder',
					Action: 'Update',
					Id:this.WorkOrder.Id,
					Status:this.WorkOrder.Status,
					Order:parseInt(this.WorkOrder.Order),
					CategoryId:this.WorkOrder.CategoryId,
					Detail:this.WorkOrder.Detail,
					AdminId:this.WorkOrder.AdminId
				}
				if(this.WorkOrder.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0 && this.WorkOrder.Id == 'add'){
						this.$Jump('/do/workorder/list')
					}else if(res.ErrorId == 0){
						this.GetWorkOrder(this.WorkOrder.Id)
					}
				})
			},
			GetCategoryList(){
				
					let data = {
						Service:'Help',
						Class: 'WorkOrderCategory',
						Action: 'List',
						Page:1,
						PageSize:20,
					}
					this.$post(this.$store.getters.getApiHost,data)
					.then((res) => {
						
						if(res.ErrorId != 0){
							this.$message(res.Msg)
							return
						}
						this.CategoryList = res.Data.WorkOrderCategoryList
						
					})
					.catch(function (response) {
						this.$message("网络请求错误")
					})
			},
			GetAdminList(){
					let data = {
						Service:'User',
						Class: 'User',
						Action: 'List',
						Identity:'admin',
						Page:1,
						PageSize:20,
					}
					this.$post(this.$store.getters.getApiHost,data)
					.then((res) => {
						
						if(res.ErrorId != 0){
							this.$message(res.Msg)
							return
						}
						this.AdminUsers = res.Data.UserList
						
					})
					.catch(function (response) {
						this.$message("网络请求错误")
					})
			},
			getFile(file, fileList) {
				console.log('jin1')
						this.getBase64(file.raw).then(res => {
							console.log('jin2')
							this.uploadPic(res)
						});
			},
			getBase64(file) {
			      return new Promise(function(resolve, reject) {
					  console.log('jin3')
			        let reader = new FileReader();
			        let imgResult = "";
			        reader.readAsDataURL(file);
			        reader.onload = function() {
			          imgResult = reader.result;
			        };
			        reader.onerror = function(error) {
			          reject(error);
			        };
			        reader.onloadend = function() {
			          resolve(imgResult);
			        };
			      });
			    },
						  uploadPic(_b64){
							  let that = this
							  let data = {
							  	Service:'Goods',
							  	Class: 'File',
							  	Action: 'Base64Upload',
							  	Base64:_b64
							  }
							  console.log('jin4')
							  this.$post(that.$store.getters.getApiHost,data)
							  .then((res) => {
							  	console.log('jin5')
							  	if(res.ErrorId != 0){
							  		that.$message(res.Msg)
							  		return
							  	}
							  	
							  	that.NewContract.ImgList.push(res.Data.Url)
							  	
							  })
							  .catch(function (response) {
							  	that.DialogMsg = '网络请求错误'
							  })
						  },
			AddContract(){
				
				let data = {
					Service:'Help',
					Class: 'WorkOrderContact',
					Action: 'Add',
					WorkOrderId:this.WorkOrder.Id,
					Detail:this.NewContract.Content,
					Images:JSON.stringify(this.NewContract.ImgList)
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0){
						this.GetContractList()
					}
				})
			},
			GetContractList(){

				let data = {
					Service:'Help',
					Class: 'WorkOrderContact',
					Action: 'List',
					WorkOrderId:this.WorkOrder.Id,
					Page:1,
					PageSize:50,
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					if(res.Data.WorkOrderContactList == undefined || res.Data.WorkOrderContactList == null)
					this.ContactList = res.Data.WorkOrderContactList
					this.Hi = JSON.stringify(res.Data.WorkOrderContactList)
					this.TalkingList = res.Data.WorkOrderContactList
				})
			},
	  }
	}
</script>

<style>
.GoodsInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.GoodsInfoSSS .Top{
	display: flex;
	align-items: center;
}
.GoodsInfoSSS .Top .Left{
	
}
.GoodsInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.GoodsInfoSSS .List{
	margin-top: 20px;	
	display: flex;
}
.GoodsInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.GoodsInfoSSS .List .One li.Sel span.Sel{
	width: 80px;
	color: rgba(0,0,0,0.3);
}
.GoodsInfoSSS .List .One li.Sel  em.Sel{
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel i.Sel{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}


.GoodsInfoSSS .List .ContractList{
	flex: 1;
	margin-left: 10px;
	padding: 10px;
	background-color: rgba(0,0,0,0.04);
}
.GoodsInfoSSS .List .ContractList h4{
	margin: 0px;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	font-size: 1.2rem;
}

.GoodsInfoSSS .List .ContractList ul{
	background-color: rgba(0,0,0,0.04);
	padding: 10px;
}
.GoodsInfoSSS .List .ContractList ul li{
	color: rgba(0,0,0,0.3);
}

.GoodsInfoSSS .List .ContractList .Images{
	display: flex;
	margin-top: 5px;
}

.GoodsInfoSSS .List .ContractList .Images .Image{
	margin-right: 10px;
	border-radius: 5px;
	border: 1px solid rgba(0,0,0,0.1);
	height: 70px;
	width: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	cursor: pointer;
	position: relative;
}
.GoodsInfoSSS .List .ContractList .Images .Image img{
	width: 100%;
	height: 100%;
}
.GoodsInfoSSS .List .ContractList .Images .Image em{
	position: absolute;
	bottom: 0px;
	right: 0px;
	padding: 5px 5px;
	background-color: rgba(0,0,0,0.4);
	color: #FFFFFF;
	font-size: 12px;
	display: none;
}
.GoodsInfoSSS .List .ContractList .Images .Image em:hover{
	background-color: rgba(228,0,0,01);
	color: #FFFFFF;
}
.GoodsInfoSSS .List .ContractList .Images .Image:hover{
	background-color: #FFFFFF;
}
.GoodsInfoSSS .List .ContractList .Images .Image:hover em{
	display: inline;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
</style>
